import request from '../utils/request'

// 活动信息获取
export function promotionGet(data) {
    return request({
        url: "/promotion/get",
        method: "post",
        data
    })
}


// 活动 列表
export function promotionQuery(data) {
    return request({
        url: "/promotion/query",
        method: "post",
        data
    })
}

// 活动 分享图片
export function promotionPosterGet(data) {
    return request({
        url: "/promotion/poster-get",
        method: "post",
        data
    })
}