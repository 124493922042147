<template>
    <div class="share-img">
        <img :src="imgUrl" alt="" @load="imgload">
    </div>
</template>

<script>
import { promotionPosterGet } from "@/api/promotion";
export default {
    data(){
        return{
            imgUrl:"",
            toast:""
        }
    },
    created(){
        this.getshareImg();
    },
    methods:{
        async getshareImg(){
            this.toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t("message.loadingTxt")
            });
            let result = await promotionPosterGet({promotionId:this.$route.query.promotionId,eventId:this.$route.query.eventId});
            // toast.clear();
            if(result.data.errorCode == 0){
                this.imgUrl = result.data.data;
            }else{
                this.$Toast(result.data.errorMsg);
            }
        },
        imgload(){
            this.toast.clear();
        }
    }
}
</script>

<style lang="scss">
.share-img{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.6rem;
    box-sizing: border-box;
    img{
        display: block;
        margin: 0 auto;
        max-width: 100%;
        max-height: 100%;
    }
}
</style>